import store from "@/store";
import {aiseoBackendPetition, uploadFileToAiseo, uploadFileToOrigin} from "@/utils/aiseo_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {getPortalCategories} from "@/utils/web_categorization";


export async function checkBrokenURLWS(links_to_send) {
    try {
        return await aicoreBackendPetition('POST', '/ScreamingFrog/check_urls_SF', JSON.stringify(links_to_send), {content_type: 'application/json'})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/ScreamingFrog/check_urls_SF');
    }
}

export async function getFieldsToPortalWS() {

    let params = {
        'portal': store.getters.getPortalID,
    }
    let params_token = {
        'portal': store.getters.getPortalID,
    };
    try {
        return await aiseoBackendPetition('POST', 'ConfigurationPortalField/getConfigurationsPortalField', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'ConfigurationPortalField/getConfigurationsPortalField');
        return {
            'server_error': true
        }
    }
}

export async function getUrlDataWS(url, type, draft = false) {
    
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'url': url
    };
    
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': url,
        'draftType': type
    }
    try {
        return await aiseoBackendPetition('POST', draft ? 'getURLDraft' : 'getURLData', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + draft ? 'getURLDraft' : 'getURLData');
        return {
            'server_error': true
        }
    }
}

export async function saveUrlDataWS(data, draft, type) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'url': data.url,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
    };
    let params = {};
    let url_draft_ws;
    let url_save_ws;
    switch (type) {
        case 'editor-course':
            url_draft_ws = 'seoPanel/saveURLCourseDraft'
            url_save_ws = 'seoPanel/publishURLCourse'
            params = {
                objectives: data.objectives !== "" ? data.objectives : null,
                publishCourse: data.publishCourse !== null ? data.publishCourse : 0,
                convocatoria_cerrada: data.convocatoria_cerrada !== null ? data.convocatoria_cerrada : 0,
                status: data.status !== null ? data.status : 0,
                titulo_curso: data.titulo_curso !== "" ? data.titulo_curso : null,
                url: data.url !== "" ? data.url : null,
                price: data.price !== "" ? data.price : null,
                subsidizedPrice: data.subsidizedPrice !== "" ? data.subsidizedPrice : null,
                campaignID: data.campaignID !== "" ? data.campaignID : null,
                simoID: data.simoID !== "" ? data.simoID : null,
                innotutorCode: data.innotutorCode !== "" ? data.innotutorCode : null,
                checkShowImage: data.checkShowImage !== null ? data.checkShowImage : 0,
                checkDiscount: data.checkDiscount !== null ? data.checkDiscount : 0,
                discountEndDate: data.discountEndDate !== "" ? data.discountEndDate : null,
                discountPercentage: data.discountPercentage !== "" ? data.discountPercentage : null,
                checkDoesntAllowDiscount: data.checkDoesntAllowDiscount !== null ? data.checkDoesntAllowDiscount : 0,
                checkFreeCourse: data.checkFreeCourse !== "" ? data.checkFreeCourse : null,
                courseTarget: data.courseTarget !== "" ? data.courseTarget : null,
                degreeDescription: data.degreeDescription !== "" ? data.degreeDescription : null,
                preparation: data.preparation !== "" ? data.preparation : null,
                citaSeo: data.cita_seo !== "" ? data.cita_seo : null,
                presentacion: data.presentacion !== "" ? data.presentacion : null,
                jobOpportunities: data.jobOpportunities !== "" ? data.jobOpportunities : null,
                summary: data.summary !== "" ? data.summary : null,
                courseAgenda: data.courseAgenda !== "" ? data.courseAgenda : null,
                courseValidations: data.courseValidations !== "" ? data.courseValidations : null,
                accessRequirements: data.accessRequirements !== "" ? data.accessRequirements : null,
                ectsCredits: data.ectsCredits !== "" ? data.ectsCredits : null,
                cfcCredits: data.cfcCredits !== "" ? data.cfcCredits : null,
                newVersion: data.newVersion !== "" ? data.newVersion : null,
                invertDegreesHierarchy: data.invert_degrees_hierarchy !== "" ? data.invert_degrees_hierarchy : 0,
                languageISO: store.getters.getLanguageISO,
                portal: store.getters.getPortalID,
                is_autoguardado: data.is_autoguardado,
            }
            break;
        case 'editor-seo':
            url_draft_ws = 'seoPanel/saveURLDraft';
            url_save_ws = 'seoPanel/publishURL'
            params = {
                /*campos curso */
                objectives: data.objectives !== "" ? data.objectives : null,
                citaSeo: data.cita_seo !== "" ? data.cita_seo : null,
                comercialTitle: data.titulo_comercial !== "" ? data.titulo_comercial : null,
                comercialSubtitle: data.subtitulo_comercial !== "" ? data.subtitulo_comercial : null,
                courseTitle: data.titulo_curso !== "" ? data.titulo_curso : null,
                kwRel1: data.kw_relacionada !== "" ? data.kw_relacionada : null,
                kwRel2: data.kw_relacionada_2 !== "" ? data.kw_relacionada_2 : null,
                kwRel3: data.kw_relacionada_3 !== "" ? data.kw_relacionada_3 : null,
                kwRel4: data.kw_relacionada_4 !== "" ? data.kw_relacionada_4 : null,
                checkReviewedTeacher: data.docentes_revisados !== null ? data.docentes_revisados : 0,
                checkReviewedOpinion: data.opiniones_revisadas !== null ? data.opiniones_revisadas : 0,
                checkSEOReview: data.seo_revisado !== null ? data.seo_revisado : 0,
                checkImgReview: data.imagen_revisada !== null ? data.imagen_revisada : 0,
                checkBacklinkReview: data.backlink_revisado !== null ? data.backlink_revisado : 0,
                checkCTRReview: data.ctr_revisado !== null ? data.ctr_revisado : 0,
                checkRelatedSEOReview: data.seo_relacionadas !== null ? data.seo_relacionadas : 0,
                checkSEOUpdated: data.seo_actualizado !== null ? data.seo_actualizado : 0,
                checkSEOMaintenance: data.mantenimiento_seo !== null ? data.mantenimiento_seo : 0,
                featuredVideo: data.video !== "" ? data.video : null,
                featuredPhrase: data.frase_destacada !== "" ? data.frase_destacada : null,
                academicDirectorVideo: data.id_video !== "" ? data.id_video : null,
                videoReview: data.id_video_review !== "" ? data.id_video_review : null,
                /*fin campos curso*/
                
                /*campos blog*/
                checkAgrupatedCourses: data.excepcion_cursos_agrupados !== null ? data.excepcion_cursos_agrupados : 0,
                completed: data.completado !== null ? data.completado : 0,
                checkNewsSection: data.seccion_noticias !== null ? data.seccion_noticias : 0,
                checkFacultyAndResearch: data.seccion_faculty_research !== null ? data.seccion_faculty_research : 0,
                featuredCourse: data.curso_destacado !== "" ? data.curso_destacado : null,
                departmentID: data.id_departamento !== "" && data.id_departamento !== 0 ? data.id_departamento : null,
                knowledgeAreaID: data.id_area_conocimiento !== "" && data.id_area_conocimiento !== 0 ? data.id_area_conocimiento : null,
                schoolID: data.id_escuela !== "" && data.id_escuela !== 0 ? data.id_escuela : null,
                descripcion_video_producto: data.descripcion_video_producto !== "" ? data.descripcion_video_producto : null,
                usable_date: data.usable_date,
                referencias_bibliograficas: variableIsDefined(data.referencias_bibliograficas) ? data.referencias_bibliograficas : null,
                /*fin campos blog*/
                
                /* campos generales */
                h1: data.h1 !== "" ? data.h1 : null,
                showedTitle: data.title !== "" ? data.title : null,
                objKW: data.keyword_objetivo !== "" ? data.keyword_objetivo : null,
                metaTitle: data.titulo_serp !== "" ? data.titulo_serp : null,
                featuredImage: data.imagen_cdn !== "" ? data.imagen_cdn : null,
                content: data.content !== "" ? data.content : null,
                articleType: data.tipo_contenido_seo !== "" ? data.tipo_contenido_seo : null,
                metaDesc: data.meta_description !== "" ? data.meta_description : null,
                categoryTID: data.categoryTID !== "" && data.categoryTID ? data.categoryTID : null,
                subcategoryTID: data.subcategoryTID !== "" && data.subcategoryTID ? data.subcategoryTID : null,
                areaTID: data.areaTID !== "" && data.areaTID !== 0 ? data.areaTID : null,
                url: data.url,
                author: data.author,
                draft_author: data.draft_author,
                languageISO: store.getters.getLanguageISO,
                portal: store.getters.getPortalID,
                is_autoguardado: data.is_autoguardado,
                /* fin campos generales */
            }

            break;
    }
    
    try {
        return await aiseoBackendPetition('POST', draft ? url_draft_ws : url_save_ws, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + draft ? url_draft_ws : url_save_ws);
    }
}

/*****
 *
 * FUNCIÓNES NUEVAS PARA FUNCIONALIDAD DE COPIAR CONTENIDO SEO
 *
 * ***************************************************************************************************/

export async function copySeoToWS(origin_url, origin_url_data, urls_to_copy) {
    let params = {
        'url_origen': origin_url,
        'idioma_origen': store.getters.getLanguageISO,
        'portal_origen': store.getters.getPortalID,
        'datos_origen': origin_url_data,
        'urls_destino': urls_to_copy,
    }
    
    return true;
}

/*COMPROBAR SI LA URL TIENE RELACIÓN CON OTRA EN EL PORTAL E IDIOMA (DEVUELVE FALSE NO TIENE RELACIÓN)*/
export async function checkURLWS(origin_portal, origin_language, origin_url, new_portal, new_language, new_url = '') {
    let params = {
        'originPortal': origin_portal,
        'originLanguageID': origin_language,
        'originURL': origin_url,
        'newPortal': new_portal,
        'newLanguageID': new_language,
        
    }
    
    if (new_url !== '') {
        params['newURL'] = new_url;
    }
    
    let params_token = {}
    
    return await aiseoBackendPetition('POST', 'seoPanel/checkURL', params, params_token)
}


/********************************************************************************************************/
export async function changeURLWS(url_old, url_new) {
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'usuario_logueado': store.getters.getUsername,
        'url': url_old,
        'newURL': url_new
    }
    
    let params = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'url': url_old,
        'newURL': url_new
    }
    
    try {
        return await aiseoBackendPetition('POST', 'changeURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'changeURL');
    }
}


export async function getArticleTypesWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'getArticleTypes', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getArticleTypes');
    }
}

export async function setFileWS(file, related_entity, related_field, related_node) {
    try {
        return await uploadFileToAiseo(file, related_entity, related_field, related_node)
    } catch (e) {
        showNotification('error', 'Se ha producido un error al enviar el fichero')
    }
}

export async function setFileToOriginWS(file, type) {
    try {
        return await uploadFileToOrigin(file, type)
    } catch (e) {
        showNotification('error', 'Se ha producido un error al enviar el fichero')
    }
}

export async function deletePageSEOWS(url) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'urls': JSON.stringify([url])
    };
    
    let params = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'urls': JSON.stringify([url])
    }
    
    try {
        return await aiseoBackendPetition('POST', 'seoPanel/removeSEOPage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/removeSEOPage');
    }
}

export async function generateContentWriteSonicWS(data) {
    let params = {
        'user': store.getters.getUsername,
        'url': data.url,
        'keyword': data.keyword,
        'id_portal': store.getters.getPortalID,
        'idioma': store.getters.getLanguageISO,
    }

    try {
        return await aicoreBackendPetition('POST', 'ArticleWriter/generador_blogs', params, {nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/removeSEOPage');
    }
}

/*
*
* A partir de un editor, obtiene su contenido
* y la longitud del texto que contiene
*
* */
export function getCharacterCount(editor) {
    let editor_content = editor.getContent({format: 'raw'});
    let editor_html_text = getHTMLText(editor_content);
    return editor_html_text;
}


/*
* Se crea un componente div al que se
* le asigna como código HTML el texto
* del editor, luego mediante innerText
* se obtiene el texto sin las etiquetas HTML
* */
export function getHTMLText(html) {
    let html_text = document.createElement("div");
    html_text.innerHTML = html;
    return html_text.innerText;
}


export async function queueExportBlogsFileWS(filters,search_url_type)
{
    let mode;

    if (search_url_type.value === 'Blogs') {
        mode = 'blogs';
    } else {
        mode = 'courses';
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params_filters = {
        'originPortal': store.getters.getPortalID,
        'originLanguageID': store.getters.getLanguageID,
        'mode': mode,
        'url_search': variableIsDefined(filters.url_search) ? filters.url_search : "",
        'url_search_type': variableIsDefined(filters.url_search_type) && variableIsDefined(filters.url_search) ? filters.url_search_type : "",
        'id_simo': variableIsDefined(filters.id_simo) ? filters.id_simo : "",
        'codigo_innotutor': variableIsDefined(filters.id_innotutor) ? filters.id_innotutor : "",
        'date_from': variableIsDefined(filters.filter_date) ? filters.filter_date : "",
        'actives': filters.state_urls,
        'category': variableIsDefined(filters.category) ? filters.category : "",
        'count_claustro': mode === 'courses' ? true : ""
    }

    if (variableIsDefined(filters.check_get_draft) && filters.check_get_draft) {
        params_filters.hasPendingDraft = 1
    }

    //parametros para crear el elemento en la cola
    let params = {
        'screen': mode,
        "params": params_filters
    };

    try {
        return await aiseoBackendPetition('POST', 'queueExportFile/setElement', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueExportFile/setElement');
    }

}