import store from "@/store";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {sincrocursoBackendPetition} from "@/utils/sincrocurso_petitition";
import {getAicoreToken} from "@/utils/aux_functions";
import axios from "axios";


export async function getCoursesByPortalWS(page = 1, rows_per_page = 10, filters) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'page_size': rows_per_page,
        'languageISO': store.getters.getLanguageISO,
    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'page_size': rows_per_page,
        'count_claustro': true,
        'name_contains': filters.name_search,
        'id_simo': filters.id_simo,
        'codigo_innotutor': filters.id_innotutor,
        'url_contains': filters.url_search,
        'count_titulaciones':true,

        'program': filters.program === -1 ? null : filters.program,
        'type': filters.type === -1 ? null : filters.type,
        'subtype': filters.subtype === -1 ? null : filters.subtype,
        'company': filters.company === '----- NINGUNA -----' ? null: filters.company,
        'degreeType': filters.degree_type === -1 ? null : filters.degree_type,
        'ids_innotutor': filters.array_id_innotutor ,
        'codigos_innotutor': filters.array_cod_innotutor,
        'ids_simo': filters.array_id_simo,
    }

    filters.category_type === 3 ? params['subcategory'] = filters.category_id : params['category'] = filters.category_id

    try {
        return await aiseoBackendPetition('POST', 'getCoursesByPortal?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' getCoursesByPortal');
    }
}

export async function getTeachersFromCourseWS(url_course) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'usuario_logueado': store.getters.getUsername,
        'languageISO': store.getters.getLanguageISO,
        'url': url_course,
        'type': 'all',
    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': url_course,
        'type': 'all',
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/getCourseTeachers', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/getCourseTeachers');
    }
}

export async function getURLByPortalWS(tipo_url = 'blogs', page = 1, rows_per_page = 20, filters, order_direction = "", order_by = "", state_url) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'mode': tipo_url,
        'page_size': rows_per_page,
        'languageISO': store.getters.getLanguageISO,
    };

    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'page_size': rows_per_page,
        'mode': tipo_url,
        'url_search': variableIsDefined(filters.url_search) ? filters.url_search : "",
        'url_search_type': variableIsDefined(filters.url_search_type) && variableIsDefined(filters.url_search) ? filters.url_search_type : "",
        'id_simo': variableIsDefined(filters.id_simo) ? filters.id_simo : "",
        'codigo_innotutor': variableIsDefined(filters.id_innotutor) ? filters.id_innotutor : "",
        'count_claustro': tipo_url.toLowerCase() !== 'blogs',
        'order_direction': order_direction,
        'order_by': order_by,
        'date_from': variableIsDefined(filters.filter_date) ? filters.filter_date : "",
        'actives': filters.state_urls,
        'category': variableIsDefined(filters.category) ? filters.category : "",
    }

    if (variableIsDefined(filters.check_get_draft) && filters.check_get_draft) {
        params.hasPendingDraft = 1
    }

    try {
        return await aiseoBackendPetition('POST', 'getPortalURLs?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' getPortalURLs');
    }
}

export async function getDuplicatedURLsWS(page = 1, rows_per_page = 20, filters) {
    let params_token = {
        // 'portal': store.getters.getPortalID,
        'page_size': rows_per_page,
        'usuario_logueado': store.getters.getUsername,
    };
    let params = {
         'id_portal': filters.selected_portal,
        'page_size': rows_per_page,
        'url_contains': variableIsDefined(filters.url_search) ? filters.url_search : "",
    }

    if (variableIsDefined(filters.action_type) && filters.action_type.trim() !== '--Todos--') {
        params.type = filters.action_type
    }

    try {
        return await aiseoBackendPetition('POST', 'getDuplicatedURLs?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' getDuplicatedURLs');
    }
}

export async function getCourseChanges(item_change) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_cambios_curso': item_change.id_change,
    };
    let params = {
        'id_portal': item_change.id_portal,
        'id_cambios_curso': item_change.id_change,
    }

    try {
        return await aiseoBackendPetition('POST', 'getCourseChanges', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' getCourseChanges');
    }
}

export async function generateAutomaticContentForCourseWS(data) {
    let dictionary_extrainformation = {};
    let cleaned_temario = "";
    let query_params = "tipo_curso_str="+data.tipo_curso+'&'

    if (variableIsDefined(data.extrainformation_to_fichaproducto)) {
        let array_data = data.extrainformation_to_fichaproducto.split(';')
        array_data.forEach(pair_value => {
            let key = pair_value.split(':')[0]
            let value = pair_value.split(':')[1]
            dictionary_extrainformation[key] = value
        })
    }
    if (variableIsDefined(data.temario)) {
        cleaned_temario = data.temario.replace(/(\r\n|\n|\r)/gm, ".")
    }


    let params = {
        'course': data.course,
        'user': store.getters.getUsername,
        'extrainformation_to_prompts': variableIsDefined(data.extrainformation_to_prompts) ? data.extrainformation_to_prompts : "",
        'extrainformation_to_fichaproducto': dictionary_extrainformation,
        'formacion_continuada': variableIsDefined(data.formacion_continuada),
        'temario': cleaned_temario,
        'modelo': data.modelo,
        'from_cola': false
    }

    data['selected_fields'].forEach((field) => {
        query_params += `${field}=true&`
    })

    try {
        return await aicoreBackendPetition('POST', 'LLMs/generador-fichas-producto/generar_ficha_producto_v2?'+ query_params, JSON.stringify(params), {content_type: 'application/json', nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/api/LLMs/generador-fichas-producto/generar_ficha_producto');
    }
}

export async function duplicateCourse(item_change) {
    let params = {
        'id_portal': item_change.id_portal,
        'id_cambios_curso': item_change.id_change,
        'username': store.getters.getUsername,
    }
    try {
        return await sincrocursoBackendPetition('POST', 'sincroAISEO/manage_duplicated_course', params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sincroAISEO/manage_duplicated_course');
    }
}

export async function updateCourseSincro(code_innotutor, simo_id) {

    let params = {
        'portal_dominio': localStorage.getItem('url_min'),
        'codigo_innotutor': code_innotutor,
        'id_simo': simo_id,
    }
    try {
        return await sincrocursoBackendPetition('POST', 'sincroAISEO/update_course_innotutor_code', params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sincroAISEO/update_course_innotutor_code');
    }
}


export async function discardDraftWS(url, draftType) {
    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername,
        url,
        draftType,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/discardDraft', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' seoPanel/discardDraft');
    }

}

export async function getFacultyFeaturedCoursesWS() {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
    };
    try {
        return await aiseoBackendPetition('POST', 'faculty/getFacultyFeaturedCourses', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/getFacultyFeaturedCourses');
    }

}

export async function setFacultyFeaturedCourseWS(id_url, weight) {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': id_url,
        'weight': weight,
        'usuario_logueado': store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'faculty/setFacultyFeaturedCourse', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/setFacultyFeaturedCourse');
    }
}

export async function changeFacultyFeaturedCourseWeightWS(id_url, weight) {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': id_url,
        'weight': weight,
        'usuario_logueado': store.getters.getUsername,
    };
    try {
        return await aiseoBackendPetition('POST', 'faculty/changeFacultyFeaturedCourseWeight', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/changeFacultyFeaturedCourseWeight ');
    }
}

export async function removeFacultyFeaturedCourseWS(id_url) {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': id_url,
        'usuario_logueado': store.getters.getUsername,
    };
    try {
        return await aiseoBackendPetition('POST', 'faculty/removeFacultyFeaturedCourse', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/removeFacultyFeaturedCourse');
    }
}


export async function getPortalCategorizationWS() {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
    };
    try {
        return await aiseoBackendPetition('POST', 'sites/getPortalCategorization', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' sites/getPortalCategorization');
    }
}


export async function getFacultyFeaturedCourses(filter_course, filter_facultad) {
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'categoryTID': filter_facultad === null ? null : filter_facultad,
        'courseNameContains': filter_course === null ? null : filter_course,
    };
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'faculty/getFacultyFeaturedCourses', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' faculty/getFacultyFeaturedCourses');
    }
}

export async function assignDegreeBulkWS(assign_type, degree_data, optional_params) {
    const {selected_urls, filters, excluded_courses} = optional_params
    let params = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'degree_data': JSON.stringify(degree_data),
        'assignType': assign_type,

        /*Opcionales*/

        'urlsIDs': assign_type === 'urls' ? selected_urls : null, /*Solo disponible si el assignType es urls*/

        /*Solo disponible si el assignType es filters*/
        'ids_innotutor': filters.array_id_innotutor ,
        'codigos_innotutor': filters.array_cod_innotutor,
        'ids_simo': filters.array_id_simo,
        'program': filters.program === -1 ? null : filters.program,
        'type': filters.type === -1 ? null : filters.type,
        'subtype': filters.subtype === -1 ? null : filters.subtype,
        'company': filters.company === '----- NINGUNA -----' ? null: filters.company,
        'degreeType': filters.degreeType === -1 ? null : filters.degreeType,
        'name': filters.name_search,
        'excludedCourses': excluded_courses,
    };


    filters.category_type === 3 ? params['subcategory'] = filters.category_id : params['category'] = filters.category_id

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'assignType': assign_type
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/assignDegreeBulk', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' degrees/assignDegreeBulk');
    }
}

export async function changeStatusUrlWS(data_url, state){
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'id_url': data_url.id_url,
        'state': state,
        'type_url': data_url.tipo_url,
    };

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'changeStatusURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' changeStatusURL');
    }
}

export async function getTypeCoursesWS(){
    try {
        return await aicoreBackendPetition('POST', 'LLMs/generador-fichas-producto/mostrar_tipos_cursos', {}, {content_type: 'application/json', nlp:true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' LLMs/generador-fichas-producto/mostrar_tipos_cursos');
    }
}

export async function queueExportCoursesFileWS()
{

    let params_token = {
        'usuario_logueado': store.getters.getUsername
    };

    let params_filters = {
        'originPortal': store.getters.getPortalID,
        'originLanguageID': store.getters.getLanguageID,
    }

    //parametros para crear el elemento en la cola
    let params = {
        'screen': 'logos_diplomas',
        'params': params_filters
    };

    try {
        return await aiseoBackendPetition('POST', 'queueExportFile/setElement', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueExportFile/setElement');
    }
}


export async function generateCourseFieldsWS(data) {
    let formData = new FormData();
    let response = await getAicoreToken({});
    let token = response['access_token']

    formData.append('file', data.imported_file)
    formData.append('user', store.getters.getUsername)

    try {
        return await axios.post(
            `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/add_excel_to_queue?id_idioma=${store.getters.getLanguageID}&peso=${parseInt(data.priority)}&chunk_size=1000`,
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/add_excel_to_queue');
        return {status: 'error'}
    }

}


export async function queueCourseFieldsDocumentsWS(page, rows_per_page, filters) {
    let response = await getAicoreToken({});
    let token = response['access_token']
    let optional_filters = {
        id_lote : filters.id_lote,
        username : filters.username,
        id_idioma: filters.language_id,
        id_estado: filters.state,
        column_order: filters.orderBy,
        type_order: filters.orderDirection,
    }

    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/get_main_table_lotes_fichas_producto?page=${page}&page_size=${rows_per_page}`

    Object.entries(optional_filters).forEach(([key, object_value]) => {
        if (variableIsDefined(object_value)){
            ws_url += `&${key}=${object_value}`
        }
    })


    try {
        return await axios.get(
            ws_url,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/get_main_table_lotes_fichas_producto');
        return {status: 'error'}
    }

}

export async function queueCourseFieldsElementTableWS(page, rows_per_page, element_id, filters) {
    let response = await getAicoreToken({});
    let token = response['access_token']
    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/get_table_lotes_fichas_producto_desglose?id_lote=${element_id}&page=${page}&page_size=${rows_per_page}`
    let optional_filters = {
        column_order: filters.orderBy,
        type_order: filters.orderDirection,
    }
    Object.entries(optional_filters).forEach(([key, object_value]) => {
        if (variableIsDefined(object_value)){
            ws_url += `&${key}=${object_value}`
        }
    })

    try {
        return await axios.get(
            ws_url,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/get_table_lotes_fichas_producto_desglose');
        return {status: 'error'}
    }
}

export async function getCourseFieldsDocumentWS(element_id, errors = false) {
    let response = await getAicoreToken({});
    let token = response['access_token']

    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/download_excel_with_results?id_lote=${element_id}`

    if (errors) {
        ws_url += `&errors=true`
    }

    try {
        return await axios.get(
            ws_url,
            {
                responseType: 'blob',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/get_table_lotes_fichas_producto_desglose');
        return {status: 'error'}
    }
}


export async function changeQueueCourseFieldStatusWS(element_id, next_state = 4) {
    let response = await getAicoreToken({});
    let token = response['access_token']

    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/stop_generating?id_lote=${element_id}`

    try {
        return await axios.post(
            ws_url,
            {},
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/get_table_lotes_fichas_producto_desglose');
        return {status: 'error'}
    }
}

export async function getQueueCourseFieldsLogsWS(page, rows_per_page, id_lote) {
    let response = await getAicoreToken({});
    let token = response['access_token']

    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/get_logs_generar_fichas_producto?page=${page}&page_size=${rows_per_page}&id_lote=${id_lote}`

    try {
        return await axios.get(
            ws_url,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/generador-fichas-producto/get_main_table_lotes_fichas_producto');
        return {status: 'error'}
    }
}


export async function getCourseTypesWS() {
    let response = await getAicoreToken({});
    let token = response['access_token']

    let ws_url = `${process.env.VUE_APP_AICORE_NLP_URL_API}LLMs/generador-fichas-producto/get_types_courses`

    try {
        return await axios.get(
            ws_url,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'LLMs/generador-fichas-producto/get_types_courses');
        return {status: 'error'}
    }
}