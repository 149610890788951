<template>
    <BaseModalTemplate
        title="Asignación de cursos a docente"
        :open-close-dialog="displayDialog"
    >
        <template #body-modal-content>
         <v-row>
             <v-col cols="12" xs="12" sm="6">
                <span class="input-title">
                    Áreas disponibles
                </span>

                <SelectTertiary
                    :items="teacher_areas"
                    v-model="selected_area"
                    item-value="id_categoria"
                    item-title="nombre"
                    class="input-error"
                    placeholder="Seleccione un área"
                    @update:modelValue="loadCoursesByTeacherArea()"
                ></SelectTertiary>
             </v-col>

             <v-col cols="12" xs="12" sm="6">
                 <span class="input-title">
                     Buscador cursos disponibles
                 </span>
                 <v-text-field
                     class="input-error text-field-secondary"
                     placeholder="Busque el nombre del curso"
                     bg-color="white"
                     hide-details
                     v-model="filter_course_name"
                 >
                 </v-text-field>
             </v-col>
             <v-col cols="12">
                 <p class="input-title">
                     Cursos seleccionados (5 como máximo)
                 </p>
                 <template v-if="array_courses.length === 0">
                     <span>
                         Actualmente sin cursos
                     </span>
                 </template>
                 <template v-else>
                     <v-row>
                         <v-col cols="auto" v-for="course in array_courses" :id="course.url"
                                class="course-list-container boxes-areas">
                             <v-card class="box_area_teacher">
                                 <v-card-text class="text-area align-self-center">
                                     {{ course.url }}
                                 </v-card-text>
                                 <div class="align-self-center p-2">
                                     <v-icon class="icons-close"
                                             @click="deselectCourse(course)">
                                         mdi-close
                                     </v-icon>
                                 </div>
                             </v-card>
                         </v-col>

                     </v-row>
                 </template>
             </v-col>
             <v-col cols="12" xs="12" sm="6">
                 <p class="input-title">
                     Cursos específicos
                 </p>
                 <v-card class="list-card personalize-scroll">
                     <v-list-item v-for="item in array_filtered.specific"
                                  v-if="array_filtered.specific.length > 0"
                                  @click="setTeacherCourses(item, 'specific')"
                     >
                         <v-list-item-title>
                             <span v-html="markSearch(item.url)"></span>
                         </v-list-item-title>
                     </v-list-item>
                     <v-list-item v-else>
                         <v-list-item-title>
                             <span> Ningún curso coincide con la búsqueda </span>
                         </v-list-item-title>
                     </v-list-item>
                 </v-card>
             </v-col>
             <v-col cols="12" xs="12" sm="6">
                 <p class="input-title">
                     Cursos basados en las áreas formativas
                 </p>
                 <v-card class="list-card personalize-scroll">
                     <template v-if="selected_area">
                         <v-list-item v-for="item in array_filtered.category"
                                      v-if="array_filtered.category.length > 0"
                                      @click="setTeacherCourses(item, selected_area)"
                         >
                             <v-list-item-title>
                                 <span v-html="markSearch(item.url)"></span>
                             </v-list-item-title>
                         </v-list-item>
                         <v-list-item v-else>
                             <v-list-item-title>
                                 <span> Ningún curso coincide con la búsqueda </span>
                             </v-list-item-title>
                         </v-list-item>
                     </template>
                     <template v-else>
                         <v-list-item>
                             <v-list-item-title>
                                 <span> Seleccione una de las áreas disponibles </span>
                             </v-list-item-title>
                         </v-list-item>
                     </template>
                 </v-card>
             </v-col>
         </v-row>
     </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <div class="btn-search-form">
                        <v-btn
                            @click="sendForm"
                            :loading="disabled_btn_save"
                            class="btn button-primary"
                            type="submit"
                            hide-details
                            typeof="submit"
                        >
                            Añadir seleccionados
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {variableIsDefined} from "@/utils/aux_functions";
import {onBeforeMount, ref, watch} from "vue";
import {initSweetAlert} from "@/utils/sweetalert";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "TeachersCoursesModal",
    components: {SelectTertiary, BaseModalTemplate},
    methods: {variableIsDefined},
    props: {
        displayDialog: Function,
        saveSelectedCourses: Function,
        teacher_areas: Array,
        array_to_show_courses: Array,
        teacher_courses: Array,
        teacher_id: Number,
        all_courses: Object
    },
    setup(props) {
        const array_courses = ref([]) /*Los max. 5 cursos que se pueden seleccionar*/
        const available_courses = ref({
            category: [],
        })
        const courses_from_areas = ref() /*Objeto con todas las áreas del docente y los cursos disponibles en cada una de ellas*/
        const disabled_btn_save = ref()
        const filter_course_name = ref('')
        const selected_area = ref(null) /*área seleccionada en el selector*/

        const array_filtered = ref({
            specific: [],
            category: []
        })

        onBeforeMount(async () => {
            initSweetAlert()
            await loadAvailableCourses()
        })

        watch(filter_course_name, (value, oldValue) => {
            if (value.length <= oldValue.length){
                applyFilterTo(true)
            }else{
                applyFilterTo()
            }
        })

        /*
        *
        * ASIGNA LOS CURSOS SELECCIONADOS
        * AL ARRAY DE CURSOS DESTACADOS DEL DOCENTE
        *
        * */
        const sendForm = async () => {
            disabled_btn_save.value = true
            props.saveSelectedCourses(array_courses.value)
            disabled_btn_save.value = false
            props.displayDialog()
        }

        /*
        *
        * Carga los cursos específicos y
        * todos los cursos por categorías
        * pero solo asigna los específicos
        * */
        const loadAvailableCourses = async () => {
            array_filtered.value['specific'] = props.all_courses.specific
            courses_from_areas.value = props.all_courses.category

            array_courses.value = Array.from(props.array_to_show_courses).sort(
                function (a, b) {
                    if (a.url.length > b.url.length) {
                        return 1;
                    }
                    if (a.url.length < b.url.length) {
                        return -1;
                    }
                    return 0;
                })
        }

        /*
        *
        * MUESTRA EL LISTADO DE CURSOS DISPONIBLES EN EL
        * ÁREA SELECCIONADA
        * */
        const loadCoursesByTeacherArea = () => {
            available_courses.value["category"] = courses_from_areas.value[selected_area.value]
            array_filtered.value['category'] = courses_from_areas.value[selected_area.value]
            filter_course_name.value = ''
        }

        /*
        *
        * CUANDO SE PULSA SOBRE CURSO
        * LO AÑADE AL ARRAY DE CURSOS SELECCIONADOS
        *
        * */
        const setTeacherCourses = (item, key) => {
            if (array_courses.value.length < 5 && !array_courses.value.some(course => course.id_url === item.id_url)) {
                let final_item
                let exist = false
                if (key === 'specific') {
                    Object.keys(courses_from_areas.value).forEach(object_key => {
                        if (courses_from_areas.value[object_key].some(course => course.id_url === item.id_url)) {
                            final_item = {...item, id_category: object_key}
                            exist = true
                        }
                    })
                }
                if (!exist) {
                    final_item = {...item, id_category: key.toString()}
                }
                array_courses.value.push(final_item)
            }
        }

        /*
        *
        * ELIMINA DEL ARRAY DE SELECCIONADOS UN CURSO
        *
        * */
        const deselectCourse = (course_id) => {
            const index = array_courses.value.findIndex(course => course.id_url === course_id.id_url)
            array_courses.value.splice(index, 1)
        }

        /*
        *
        * MUESTRA EL LISTADO DE CURSOS SEGÚN
        * EL VALOR ACTUAL DEL PARÁMETRO DE BÚSQUEDA
        *
        * */
        const applyFilterTo = (search_in_complete = false) => {
            if(search_in_complete || !variableIsDefined(filter_course_name.value)){
                array_filtered.value['specific'] = props.all_courses.specific
                array_filtered.value['category'] = available_courses.value["category"]
            }

            if (variableIsDefined(filter_course_name.value)){
                array_filtered.value['specific'] = array_filtered.value['specific'].filter((course) => {
                    return course.url.toUpperCase().includes(filter_course_name.value.toUpperCase())
                })

                if(selected_area.value){
                    array_filtered.value['category'] = array_filtered.value['category'].filter((course) => {
                        return course.url.toUpperCase().includes(filter_course_name.value.toUpperCase())
                    })
                }
            }
        }

        /*
        *
        * Cambia el color del texto
        * para mostrar la parte que coincide
        * con el parámetro de búsqueda
        * */
        const markSearch = (text) => {
            const new_text = text.toUpperCase().replaceAll(filter_course_name.value.toUpperCase(), `<strong style="background: #DADCE0">${filter_course_name.value.toUpperCase()}</strong>`)
            return `<span>${new_text}</span>`
        }

        return {
            applyFilterTo,
            deselectCourse,
            loadAvailableCourses,
            loadCoursesByTeacherArea,
            markSearch,
            sendForm,
            setTeacherCourses,

            array_filtered,
            array_courses,
            disabled_btn_save,
            filter_course_name,
            selected_area,
        }
    }
}
</script>

<style lang="scss" scoped>
.course-list-container {
    display: flex;
    justify-content: space-between;
}

.boxes-areas {
    .box_area_teacher {
        box-shadow: none;
        height: auto;
        width: fit-content;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $blue-medium;
        border: 1px solid $blue-medium;

        .text-area {
            padding: 3px 15px;
        }

        .icons-close {
            filter: brightness(0) saturate(100%) invert(90%) sepia(17%) saturate(3681%) hue-rotate(321deg) brightness(96%) contrast(98%);
        }

        .icons-loading {
            display: none;
        }

    }
}

.list-card {
    box-shadow: none;
    border: 1px solid $blue-medium;
    max-height: 40vh;
    overflow: scroll;
}
</style>